.App {
  background-color: #908E7B;
  width: 950px;
  margin-left: 1px;
}

.App-inner-body {
  margin: 0px 50px 0px;

}

.hdrStuff {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.style7 {
  color: Brown;
  font-size: 55px;
  font-weight: bold;
  line-height: 1;
  font-family: Palatino;
}

.style3 {
  margin-top: 30px;
  color: black;
  font-size: 20px;
  font-family: tahoma;
  font-style: italic;
}

.no-bullets {
  list-style-type: none; 
  padding: 0; 
  margin: 20px; 
  text-align: left;
}

.width300 {
  margin-left: 0px;
  width: 300px;
}

.width-48 {
  width: 400px;
}

.rep-h1 {
  text-align: center;
}

.body-style {
  border-radius: 25px;
  padding: 9px 0px 5px 2px; 
  background-color: #878571;
  width: 48%;
}

.contact-intro {
  padding: 9px 0px 5px 10px; 
  border-radius: 25px;
  background-color: #878571;
  color: black;
  font-size: 18px;
  font-family: tahoma;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.contact-pane {
  background-color: #878571;
  color: black;
  font-size: 18px;
  font-family: tahoma;
  height: 500px;
  margin-right: 20px;
}

.contact-btn {
  color: black;
  font-size: 18px;
  font-family: tahoma;
  font-weight: bold;
}

.contact-input {
  font-size: 16px;
  font-family: tahoma;
  background-color: lightgoldenrodyellow;

}

.contact-submit {
  align-content: center;
  font-size: 20px;
  background-color: lightgoldenrodyellow;
}

.body-title-style {
  border-radius: 25px 25px 0 0;
  padding: 9px 0px 5px 5px;
  background-color: #878571;
  width: 100%;
}

.body-style2 {
  border-radius: 0 0 25px 25px;
  padding: 9px 0px 5px 5px;
  background-color: #878571;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  flex: 0 0 49%;
}

.style4 {
  color: black;
  font-size: 16px;
  line-height: 1.5;
  font-family: tahoma;
  background-color: #878571;
}

.style5 {
  color: black;
  font-size: 16px;
  font-family: tahoma;
  background-color: #878571;
}

.rubiaImg {
  margin-top: 20px;
  margin-left: 20px;
  height: 300px;
  width: 450px;
}

.bio {
  padding-left: 5px;
}

.quote {
  padding-left: 5px;
  text-align: center;
  font-style: italic;
}

.piece-title {
  margin: 0;
  padding-left: 20px;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.App-footer {
  margin-top: 10px;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  background-color: #878571;
}

.App-header {
  background-color: #908E7B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tab-container {
  padding-top: 3px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: stretch;
}

.tab-holder {
  position: relative;
  z-index: 5;
  flex-grow: 1;
}

.tab {
  background-color: #4F6690;
  font-size: 20px;
  padding: 10px;
  color: white;
  border-radius: 5px 5px 0 0;
  border: 1px solid black;
  text-align: center;
  flex-grow: 1;
}

div.tab:hover {
  background-color: #b38cee!important;
}

.slide {
  position: absolute;
  height: 300px;
  width: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid Brown;
  border-radius:15px;
}

.slide-enter {
  position: absolute;
  opacity: 0;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 5s ease;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}


.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #4F6690;
  border: 1px solid #474a4d;;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.menu-item {
  padding: 3px;
  background: transparent;
  color: white;
  font-size: 18px;
  width: 100%;
}

.menu-item:hover {
  background: #b38cee;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0);
  transition: all 400ms ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 400ms ease;
}

.menu-secondary-enter {
  position: absolute;
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0);
  transition: all 400ms ease;
}

.menu-secondary-exit {
  position: absolute;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 400ms ease;
}

.icon-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
}

.nav-item > .icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill:  #dadce1;
  width: 24px;
  height: 24px;
}

.recordings-div {
  padding:30px;
  font: Tacoma;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-iframe {
  display:flex; 
  justify-content:center; 
  align-items:center; 
} 
