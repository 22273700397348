.player {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	 border-radius: 10px;
	 display: flex;
	 flex-direction: column;
	 justify-content: space-between;
	 align-items: center;
	 padding-bottom: 20px;
	 background-color: #212527;
	 width: 600px;
}
.cur__song {
	text-align: center;
	margin: 10px;
	color: white;
	font-family: Permanent Marker;
	font-weight: normal;
	font-size: 16px;
}
.audio--paragraph {
	margin: 20px;
	color: black;
	font-family: Permanent Marker;
	font-weight: normal;
	font-size: 16px;
	text-align: center;
}

 .track {
	 text-align: left;
	 margin: 10px;
	 color: black;
	 font-family: Permanent Marker;
	 font-weight: normal;
	 font-size: 16px;
}
 .track:hover {
	 font-weight: bold;
}
 .player .controls {
	 width: 100%;
     margin-top: 20px;
	 flex-grow: 1;
	 margin: 0 20px;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
}
 .player .player__button {
	 width: fit-content;
	 margin-bottom: 0px;
	 background-color: transparent;
	 border: none;
}
 .player .player__button:focus {
	 outline: none;
}
 .player .player__button:hover {
	 cursor: pointer;
}
 .player .player__button:hover svg {
	 color: greenyellow;
}
 .player .player__button svg {
	 font-size: 4em;
	 color: white;
}
 .player .bar {
	 user-select: none;
	 width: 90%;
	 display: flex;
	 align-items: center;
}
 .player .bar .bar__time {
	 color: white;
	 font-size: 16px;
}
 .player .bar .bar__progress {
	 flex: 1;
	 border-radius: 5px;
	 margin: 0 20px;
	 height: 10px;
	 display: flex;
	 align-items: center;
	 cursor: pointer;
}
 .player .bar .bar__progress .bar__progress__knob {
	 position: relative;
	 height: 16px;
	 width: 16px;
	 border: 1.5px solid white;
	 border-radius: 50%;
	 background-color: orange;
}
.player .image {
	width: 300px;
	margin-top: 2px;
}
.controls {
	position: relative;
}
.tracks {
	position: absolute;
	border-radius: 5px;
	left: 5px;
	top: 26px;
	background-color: white;
}
.tracks-button {
	position: absolute;
	border-radius: 5px;
	margin: 5px;
	left: 0;
	top: 0;
	width: 30px;
  	height: 30px;
  	display: flex;
  	justify-content: center;
  	align-items: center;
}