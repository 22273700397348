body {
  margin: auto;
  max-width: 950px;
  background-color: #908E7B;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #908E7B;
  width: 950px;
  margin-left: 1px;
}

.App-inner-body {
  margin: 0px 50px 0px;

}

.hdrStuff {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.style7 {
  color: Brown;
  font-size: 55px;
  font-weight: bold;
  line-height: 1;
  font-family: Palatino;
}

.style3 {
  margin-top: 30px;
  color: black;
  font-size: 20px;
  font-family: tahoma;
  font-style: italic;
}

.no-bullets {
  list-style-type: none; 
  padding: 0; 
  margin: 20px; 
  text-align: left;
}

.width300 {
  margin-left: 0px;
  width: 300px;
}

.width-48 {
  width: 400px;
}

.rep-h1 {
  text-align: center;
}

.body-style {
  border-radius: 25px;
  padding: 9px 0px 5px 2px; 
  background-color: #878571;
  width: 48%;
}

.contact-intro {
  padding: 9px 0px 5px 10px; 
  border-radius: 25px;
  background-color: #878571;
  color: black;
  font-size: 18px;
  font-family: tahoma;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.contact-pane {
  background-color: #878571;
  color: black;
  font-size: 18px;
  font-family: tahoma;
  height: 500px;
  margin-right: 20px;
}

.contact-btn {
  color: black;
  font-size: 18px;
  font-family: tahoma;
  font-weight: bold;
}

.contact-input {
  font-size: 16px;
  font-family: tahoma;
  background-color: lightgoldenrodyellow;

}

.contact-submit {
  align-content: center;
  font-size: 20px;
  background-color: lightgoldenrodyellow;
}

.body-title-style {
  border-radius: 25px 25px 0 0;
  padding: 9px 0px 5px 5px;
  background-color: #878571;
  width: 100%;
}

.body-style2 {
  border-radius: 0 0 25px 25px;
  padding: 9px 0px 5px 5px;
  background-color: #878571;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  flex: 0 0 49%;
}

.style4 {
  color: black;
  font-size: 16px;
  line-height: 1.5;
  font-family: tahoma;
  background-color: #878571;
}

.style5 {
  color: black;
  font-size: 16px;
  font-family: tahoma;
  background-color: #878571;
}

.rubiaImg {
  margin-top: 20px;
  margin-left: 20px;
  height: 300px;
  width: 450px;
}

.bio {
  padding-left: 5px;
}

.quote {
  padding-left: 5px;
  text-align: center;
  font-style: italic;
}

.piece-title {
  margin: 0;
  padding-left: 20px;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.App-footer {
  margin-top: 10px;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  background-color: #878571;
}

.App-header {
  background-color: #908E7B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tab-container {
  padding-top: 3px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: stretch;
}

.tab-holder {
  position: relative;
  z-index: 5;
  flex-grow: 1;
}

.tab {
  background-color: #4F6690;
  font-size: 20px;
  padding: 10px;
  color: white;
  border-radius: 5px 5px 0 0;
  border: 1px solid black;
  text-align: center;
  flex-grow: 1;
}

div.tab:hover {
  background-color: #b38cee!important;
}

.slide {
  position: absolute;
  height: 300px;
  width: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid Brown;
  border-radius:15px;
}

.slide-enter {
  position: absolute;
  opacity: 0;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 5s ease;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}


.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #4F6690;
  border: 1px solid #474a4d;;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.menu-item {
  padding: 3px;
  background: transparent;
  color: white;
  font-size: 18px;
  width: 100%;
}

.menu-item:hover {
  background: #b38cee;
}

.menu-primary-enter {
  position: absolute;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}

.menu-primary-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: all 400ms ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  transition: all 400ms ease;
}

.menu-secondary-enter {
  position: absolute;
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}

.menu-secondary-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: all 400ms ease;
}

.menu-secondary-exit {
  position: absolute;
}

.menu-secondary-exit-active {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
  transition: all 400ms ease;
}

.icon-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
}

.nav-item > .icon-button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.icon-button svg {
  fill:  #dadce1;
  width: 24px;
  height: 24px;
}

.recordings-div {
  padding:30px;
  font: Tacoma;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-iframe {
  display:flex; 
  justify-content:center; 
  align-items:center; 
} 

.player {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	 border-radius: 10px;
	 display: flex;
	 flex-direction: column;
	 justify-content: space-between;
	 align-items: center;
	 padding-bottom: 20px;
	 background-color: #212527;
	 width: 600px;
}
.cur__song {
	text-align: center;
	margin: 10px;
	color: white;
	font-family: Permanent Marker;
	font-weight: normal;
	font-size: 16px;
}
.audio--paragraph {
	margin: 20px;
	color: black;
	font-family: Permanent Marker;
	font-weight: normal;
	font-size: 16px;
	text-align: center;
}

 .track {
	 text-align: left;
	 margin: 10px;
	 color: black;
	 font-family: Permanent Marker;
	 font-weight: normal;
	 font-size: 16px;
}
 .track:hover {
	 font-weight: bold;
}
 .player .controls {
	 width: 100%;
     margin-top: 20px;
	 flex-grow: 1;
	 margin: 0 20px;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
}
 .player .player__button {
	 width: -webkit-fit-content;
	 width: -moz-fit-content;
	 width: fit-content;
	 margin-bottom: 0px;
	 background-color: transparent;
	 border: none;
}
 .player .player__button:focus {
	 outline: none;
}
 .player .player__button:hover {
	 cursor: pointer;
}
 .player .player__button:hover svg {
	 color: greenyellow;
}
 .player .player__button svg {
	 font-size: 4em;
	 color: white;
}
 .player .bar {
	 -webkit-user-select: none;
	         user-select: none;
	 width: 90%;
	 display: flex;
	 align-items: center;
}
 .player .bar .bar__time {
	 color: white;
	 font-size: 16px;
}
 .player .bar .bar__progress {
	 flex: 1 1;
	 border-radius: 5px;
	 margin: 0 20px;
	 height: 10px;
	 display: flex;
	 align-items: center;
	 cursor: pointer;
}
 .player .bar .bar__progress .bar__progress__knob {
	 position: relative;
	 height: 16px;
	 width: 16px;
	 border: 1.5px solid white;
	 border-radius: 50%;
	 background-color: orange;
}
.player .image {
	width: 300px;
	margin-top: 2px;
}
.controls {
	position: relative;
}
.tracks {
	position: absolute;
	border-radius: 5px;
	left: 5px;
	top: 26px;
	background-color: white;
}
.tracks-button {
	position: absolute;
	border-radius: 5px;
	margin: 5px;
	left: 0;
	top: 0;
	width: 30px;
  	height: 30px;
  	display: flex;
  	justify-content: center;
  	align-items: center;
}
